import { memo, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledWrapper } from './styles'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  analyzeFreeSpeechAnswer,
  analyzeGuidedAnswer,
  finishVrActivity,
  getActivityInfo,
  saveQuestionResult,
  startVrActivity,
  transcribeAnswer
} from './services'
import { clearState } from './slices'
import { VrInteractive } from 'beyond-words-vr-module'

function Activity() {
  navigator.mediaDevices.getUserMedia({ audio: true })
  const navigate = useNavigate()
  const { activityId, assignedActivityId } = useParams()

  const dispatch = useAppDispatch()
  const activity = useAppSelector((state) => state.activity.activityInfo)

  const completeCallback = useCallback(() => {
    if (activity) {
      const { unit } = activity
      const currentRoute =
        APP_ROUTES.END_OF_ACTIVITY +
        `?unitId=${unit.id}&courseId=${unit.course.id}`
      navigate(currentRoute)
    }
  }, [activity])

  const startCallback = useCallback(async (): Promise<{
    id: string
  }> => {
    const res = await dispatch(
      startVrActivity({ id: assignedActivityId as string })
    ).unwrap()
    return res
  }, [assignedActivityId])

  const finishTrackingCallback = useCallback(async (resultId: string) => {
    const finishedAt = new Date().toISOString()
    dispatch(finishVrActivity({ resultId, finishedAt }))
  }, [])

  const transcribeCallback = useCallback(
    async (formData: FormData): Promise<{ transcription: string }> => {
      const res = await dispatch(transcribeAnswer(formData)).unwrap()
      return res
    },
    []
  )

  const saveQuestionResultCallback = useCallback(
    async ({ score, id }: { id: string; score: number }): Promise<void> => {
      dispatch(saveQuestionResult({ resultId: id, score }))
      return undefined
    },
    []
  )

  const guidedAnalyzeCallback = useCallback(
    async ({
      maxScoreAnswerId,
      formData
    }: {
      maxScoreAnswerId: string
      formData: FormData
    }): Promise<void> => {
      dispatch(analyzeGuidedAnswer({ maxScoreAnswerId, formData }))
    },
    []
  )

  const freeSpeechAnalyzeCallback = useCallback(
    async ({
      questionId,
      formData
    }: {
      questionId: string
      formData: FormData
    }): Promise<void> => {
      dispatch(analyzeFreeSpeechAnswer({ questionId, formData }))
    },
    []
  )

  useEffect(() => {
    dispatch(getActivityInfo({ id: activityId as string }))

    return () => {
      dispatch(clearState())
    }
  }, [])

  if (!activity) return null

  return (
    <StyledWrapper>
      <VrInteractive
        activityMode={activity.mode}
        questions={activity.questions}
        sceneUrl={activity.sceneUrl}
        language={activity.unit.course.language || 'en'}
        completeCallback={completeCallback}
        startCallback={startCallback}
        finishTrackingCallback={finishTrackingCallback}
        transcribeCallback={transcribeCallback}
        saveQuestionResultCallback={saveQuestionResultCallback}
        guidedAnalyzeCallback={guidedAnalyzeCallback}
        freeSpeechAnalyzeCallback={freeSpeechAnalyzeCallback}
      />
    </StyledWrapper>
  )
}

export default memo(Activity)

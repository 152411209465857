import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, patch, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import { IActivityInfo, IGetActivityInfo } from '../types'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getActivityInfo = createAsyncThunk<
  IActivityInfo,
  IGetActivityInfo,
  ICommonChunkConfig
>('activity/fetchActivityInfo', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.ACTIVITY_BY_ID}/${_data.id}`,
      params: { id: _data.id }
    })
    return response as IActivityInfo
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const transcribeAnswer = createAsyncThunk<
  { transcription: string },
  FormData,
  ICommonChunkConfig
>('activity/transcribeAnswer', async (data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: API_ROUTES.TRANSCRIBE,
      data
    })
    return response as { transcription: string }
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const analyzeGuidedAnswer = createAsyncThunk<
  void,
  { maxScoreAnswerId: string; formData: FormData },
  ICommonChunkConfig
>(
  'activity/analyzeGuidedAnswer',
  async ({ maxScoreAnswerId, formData }, { rejectWithValue }) => {
    try {
      await post({
        path: API_ROUTES.GUIDED_ANALYZE(maxScoreAnswerId),
        data: formData
      })
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const analyzeFreeSpeechAnswer = createAsyncThunk<
  void,
  { questionId: string; formData: FormData },
  ICommonChunkConfig
>(
  'activity/analyzeFreeSpeechAnswer',
  async ({ questionId, formData }, { rejectWithValue }) => {
    try {
      await post({
        path: API_ROUTES.FREE_SPEECH_ANALYZE(questionId),
        data: formData
      })
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const startVrActivity = createAsyncThunk<
  { id: string },
  { id: string },
  ICommonChunkConfig
>('activity/startVrActivity', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await post({
      path: API_ROUTES.VR_RESULT(id),
      data: {}
    })
    return response as { id: string }
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const finishVrActivity = createAsyncThunk<
  void,
  { resultId: string; finishedAt: string },
  ICommonChunkConfig
>(
  'activity/finishVrActivity',
  async ({ resultId, finishedAt }, { rejectWithValue }) => {
    try {
      await patch({
        path: API_ROUTES.VR_RESULT(resultId),
        data: { finishedAt }
      })
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const saveQuestionResult = createAsyncThunk<
  void,
  { resultId: string; score: number },
  ICommonChunkConfig
>(
  'activity/saveQuestionResult',
  async ({ resultId, score }, { rejectWithValue }) => {
    try {
      await patch({
        path: API_ROUTES.VR_RESULT(resultId),
        data: { score }
      })
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

import { FC, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../../../components/CustomModal'
import { StyledWrapper } from './styles'
import { VrPreview } from 'beyond-words-vr-module'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getActivityInfo } from '../../../Activity/services'
import { clearState } from '../../../Activity/slices'

interface Props {
  isOpen: boolean
  activityId: string
  handleClose: () => void
}

const PreviewModal: FC<Props> = ({ isOpen, handleClose, activityId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const activity = useAppSelector((state) => state.activity.activityInfo)

  useEffect(() => {
    dispatch(getActivityInfo({ id: activityId }))

    return () => {
      dispatch(clearState())
    }
  }, [])

  return (
    <CustomModal
      width="90vw"
      open={isOpen}
      title={t('preview')}
      handleClose={handleClose}
    >
      <StyledWrapper>
        {activity && (
          <VrPreview
            activityMode={activity.mode}
            questions={activity.questions}
            sceneUrl={activity.sceneUrl}
            language={activity.unit.course.language || 'en'}
            completeCallback={handleClose}
          />
        )}
      </StyledWrapper>
    </CustomModal>
  )
}

export default memo(PreviewModal)
